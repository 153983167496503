<template>
  <!-- <div class="home  home_pc"> -->
  <div class="domain_content">
    <section id="section-1" class="section-content no-padding">
      <div class="head-row">
        <div class="app-intro">
          <h1 class="section-1-rule" v-html="$global.formatstr(doc.s1.h1, month)"></h1>
          <p>
            If you're searching for a messaging app that goes beyond the basics, WhatsApp Plus is the perfect solution. The latest version of WhatsApp Plus builds on the popular WhatsApp platform with added features and customization options. While it maintains a similar design to the original app, WhatsApp Plus introduces an array of enhanced functionalities that make it stand out.
          </p>
          <myimg name="plus-new" alt="whatsapp plus apk" />

        </div>
      </div>

      <category :arr="cate" />

      <h2 id="d2">Download WhatsApp Plus APK</h2>

      <ApkDetail :doc="doc" id="d1"/>

      <DownloadBtn class="top-download fix has-exp" :exp-data="`show_${pageName}home_download`"
        :name="pageName" :from="from" :filename="filename" :isOffical="isOffical" txt="WhatsApp Plus"/>

      <h2 id="d2">Why Choose WhatsApp Plus?</h2>
      <p>
        WhatsApp Plus offers more control over privacy and customization settings compared to WhatsApp, making it a popular choice for those seeking an elevated messaging experience. While its user base is smaller than apps like GB WhatsApp, it also poses a lower risk of account bans.
      </p>

      <h2 id="d3">WhatsApp
          Plus vs WhatsApp: Key Difference?</h2>
      <p>
        WhatsApp Plus enhances WhatsApp with features that boost privacy, customization, and functionality. From aesthetic themes to privacy settings like hiding your online status, WhatsApp Plus allows users to personalize their experience. Below are some standout features that differentiate WhatsApp Plus from the original WhatsApp:      </p>

      <table id="playlist" cellspacing="0">
        <tbody>
          <tr>
            <th style="width:29%">Feature</th>
            <th>WhatsApp Plus</th>
            <th>WhatsApp</th>
          </tr>
          <tr v-for="(data, i) in feature" :key="i">
            <td>
              <div v-html="data.feature"></div>
            </td>
            <td>
              <div v-html="data.wp"></div>
            </td>
            <td>
              <div v-html="data.whs"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <S2 :doc="doc" />

    <S3 :doc="doc" :pageName="pageName" />

    <div id="snippet-box">
      <div class="snippet-title">Summary</div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">Author Rating</div>
          <div class="snippet-data-img"><span class="star-img"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span></div>
          <div class="snippet-clear"></div>
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">Aggregate Rating</div>
            <div class="snippet-data-img"><span itemprop="ratingValue">3.65</span> based on <span
                class="rating-count" itemprop="ratingCount">46258</span> votes </div>
          </div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Name</div>
          <div class="snippet-data-img"> <span itemprop="name">WhatsApp Plus APK</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Operating System</div>
          <div class="snippet-data-img"> <span itemprop="operatingSystem">Android</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Software Category</div>
          <div class="snippet-data-img"> <span itemprop="applicationCategory">App</span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Price</div>
          <div class="snippet-data-img"> <span itemprop="offers" itemscope=""
              itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span></div>
          <div class="snippet-clear"></div>
          <div class="snippet-label-img">Landing Page</div>
          <div class="snippet-data-img"> <a itemprop="featureList"
              href="https://gbapksdownload.com/whatsapp2plus.html">https://gbapksdownload.com/whatsapp2plus.html</a>
          </div>
          <div class="snippet-clear"></div>
        </div>
      </div>
    </div>

    <Rate />

  </div>
</template>

<script>
// @ is an alias to /src
import "@/css/gb/pc.scss";
import DownloadBtn from "@/components/DownloadBtnNewText.vue";
import S2 from "./assets/section2.vue";
import S3 from "./assets/section3.vue";
import ApkDetail from "./assets/apkdetail.vue";
import Rate from "@/components/rate.vue";
import category from "@/components/category.vue";

export default {
  name: "plus",
  components: {
    DownloadBtn,
    S2,
    S3,
    ApkDetail,
    Rate,
    category,
  },
  data () {
    return {
      pageName: "plus",
      doc: null,
      myApp: null,
      from: null,
      filename: null,
      isOffical: 0,
      feature: [
        {
            feature: "Ghost Mode",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Save Status",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Hide Blue Ticks",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Unread Message",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Schedule Message",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Read Deleted Messages",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Auto Reply",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-0"></div>',
        },
        {
            feature: "Themes",
            wp: "4000+",
            whs: "limited",
        },
        {
            feature: "Image Quality",
            wp: "original",
            whs: "compressed",
        },
        {
            feature: "Customer Service",
            wp: '<div class="check-0"></div>',
            whs: '<div class="check-1"></div>',
        },
        {
            feature: "App Lock",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-1"></div>',
        },
        {
            feature: "Freeze Last Seen",
            wp: '<div class="check-1"></div>',
            whs: '<div class="check-1"></div>',
        },
      ],
      cate: [
        "1. Download WhatsApp Plus APK",
        "2. Why Choose WhatsApp Plus?",
        "3. WhatsApp Plus vs. WhatsApp: Key Differences",
        "4. Main Features of WhatsApp Plus APK",
        "5. What's New in WhatsApp Plus V18.10?",
        "6. How to Download and Install WhatsApp Plus",
        "7. Conclusion"
      ],

      viewHeight: null,
      scrollEventFun: null,
      month: "",

      ratingStar: null,
      showCollect: false,
    };
  },
  created () {
    this.myApp = this.$store.state.plus;
    this.doc = this.myApp.doc;
  },
  beforeMount () {
    [this.from, this.filename, this.isOffical] = [
      this.$global.GetQueryString("from") || "",
      this.$global.GetQueryString("filename") || "",
      parseInt(this.$global.GetQueryString("isOffical")) || 0,
    ];
    console.log(`from:${this.from},filename:${this.filename}`);

    console.log(this.myApp);
    this.$store.state.activeName = this.myApp.name;
    this.$store.state.activeLogo = this.myApp.logo;

    const monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.month = monthArray[new Date().getMonth()];

    this.$logEvent(`show_plushome_${this.$route.name}`);
  },
  mounted () {
    console.log("mounted");
    let script = document.querySelector("#script");
    if (script) {
      return;
    }

    let recaptchaScript = document.createElement("script");
    recaptchaScript.async = true;
    recaptchaScript.id = "script";
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3240446016388496"
    );
    recaptchaScript.setAttribute("crossorigin", "anonymous");
    document.head.appendChild(recaptchaScript);
    setTimeout(() => {
      if (typeof window !== "undefined") {
        this.viewHeight = window.innerHeight;
        this.scrollEvent();
        // 监听scroll事件
        this.scrollEventFun = this.$global.throttle(
          this.scrollEvent,
          300
        );
        window.addEventListener("scroll", this.scrollEventFun, false);
      }

      if (this.$route.params.scrollTo) {
        document
          .querySelector(`#${this.$route.params.scrollTo}`)
          .scrollIntoView({
            behavior: "smooth",
          });
      }
    }, 1000);
  },
  beforeDestroy () {
    // 防止内存溢出
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.scrollEventFun, false);
    }
  },
  methods: {
    getOffsetTop (element) {
      let actualTop = element.offsetTop;
      let current = element.offsetParent;
      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      return actualTop;
    },
    // 获取窗口滚动高度
    scrollTop () {
      return (
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      );
    },
    scrollEvent () {
      let $scrollTop = this.scrollTop();
      let nodeArr = document.querySelectorAll(".has-exp");
      if ($scrollTop >= 20) {
        this.showTeleMsg = false;
      }
      for (let i = 0; i < nodeArr.length; i++) {
        let domTop =
          this.getOffsetTop(nodeArr[i]) +
          nodeArr[i].getBoundingClientRect().height / 3;
        // 当正文部分露出即发此曝光
        if (nodeArr[i].getAttribute("detail-article") === "1") {
          domTop = this.getOffsetTop(nodeArr[i]);
        }
        let topHeight = this.getOffsetTop(nodeArr[i]) - $scrollTop;
        // console.log('topHeight', topHeight);
        let bottomHeight = domTop - $scrollTop;
        if (topHeight >= 0 && bottomHeight <= this.viewHeight) {
          if (!nodeArr[i].getAttribute("data-exposure")) {
            nodeArr[i].setAttribute("data-exposure", "1");
            const _this = this;
            new Promise((resolve) => {
              resolve();
            }).then(() => {
              let expData = nodeArr[i].getAttribute("exp-data");
              _this.expPost(expData);
            });
          }
        }
        if (topHeight > this.viewHeight) return false;
      }
    },
    expPost (exposeData) {
      this.$logEvent(exposeData);
      console.log(`曝光统计log ${exposeData}`);
    },
    download () {
      // this.$global.download(this.myApp.apk);
      this.$logEvent("click_gwhome_download");
      this.$router.push({
        name: "plus_download_pc",
        params: { apk: this.myApp.apk },
      });
    },
    gotochat () {
      if (typeof window !== "undefined") {
        // 在浏览器环境中执行的代码
        window.location.href = "https://www.whatsplus.online/";
      }
    },
  },
};
</script>
